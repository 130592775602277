<template>

    <div class="container">
      <div class="row justify-content-md-center mt-4 mb-2 pb-row">
        <div class="col-12 col-lg-8 text-center login-signup">
          <h4>Confirm Email</h4>
        </div>
      </div>

      <div class="row justify-content-md-center mt-4 mb-2 pb-row">
        <div class="col-12 col-lg-8 text-center pb-5 login-signup-form">
          <div class="row">
            <div class="col-12 col-lg-8 text-left pb-4 mg-auto">
               <p>Check your email to confirm your account. Click the link to verify your email; alternately you can enter the code here.</p>
               <hr/>
               <form>
                <div class="form-group">
                  <label for="inputEmail1">Verification Code</label>
                  <input type="text" v-model="verificationCode" class="form-control" id="verificationCode" placeholder="code" autofocus>
                </div>

               <b-list-group v-show="errors.length > 0">
                  <b-list-group-item
                  v-for="error in errors"
                  :key="error"
                  variant="danger"
                  >{{ error }}</b-list-group-item
                  >
               </b-list-group>


                <a href="login" @click="$event.preventDefault(); verifyCode();" class="btn btn-orange btn-lg btn-block login-btn mt-5" v-if="verificationCode !== ''">Confirm</a>
                <a href="login" @click="$event.preventDefault(); " class="btn btn-orange-disabled btn-lg btn-block login-btn mt-5" v-else>Confirm</a>

                <a href="login" @click="$event.preventDefault(); cancelVerify();" class="btn btn-secondary btn-lg btn-block">Cancel</a>

                <a href="reset-password" @click="$event.preventDefault(); $router.push({name: 'Reset Password'});" class="btn btn-secondary btn-lg btn-block">Re-Send Code</a>
              </form>
          </div>
        </div>
      </div>
    </div>

    </div><!--END CONTAINER-->



</template>

<script>

export default {
   name: "Verify",
   data() {
      return {
         verificationCode: "",
         errors: []
      };
   },
   mounted() {
      this.$store.commit("setDidPromptPurchaseOptions", false);

      this.$store.commit('logout');
      if (this.$route.query.code) {
         this.verificationCode = this.$route.query.code;
         this.verifyCode();
      }
   },
   methods: {
      cancelVerify() {
         this.axios
            .post("/api/logout", {}, { withCredentials: true })
            .then((response) => {
               console.log(response);
               this.$store.commit('logout');
            })
            .catch((error) => {
               console.log(error);
            });

         window.location.assign("login");
      },
      verifyCode() {
         this.errors = [];

         const to_send = {
            verificationCode: this.verificationCode
         };
         this.axios
            .post("/api/verify-email", to_send, { withCredentials: true })
            .then((response) => {
               if (response.data && response.data.status == "success") {

                  this.$store.commit("setAccountData", response.data.account_data);
                  this.$store.commit("setDidPromptPurchaseOptions", false);
                  this.$store.commit("setPromptPurchaseOptions", true);
                  if (this.$store.state.purchaseSliceCount) {
                     this.$store.commit("setDidPromptPurchaseOptions", true);
                     window.location.assign("payment");
                  }
                  else {
                     window.location.assign("/");
                  }
               }
               else {
                  this.errors = [response && response.data.status ? response.data.status : "Invalid code. Please check your input and try again."];
               }
               
            })
            .catch((error) => {
               if (error.response) {
                  this.errors.push("Invalid entry; please check your inputs and try again.");
               } else if (error.message) {
                  this.errors.push("Something went wrong, please try again.");
               }
            });
      },
   },
};
</script>
